import React from "react";

import NavMenuList from "./NavMenuList/NavMenuList";

import {apps, otherProjects} from "components/consts";

const NavMenu = (props) => {
	const {
		className,
		toggleMenu
	} = props;

	return (
		<div className={`nav-menu ${className || ""}`}>
			<NavMenuList list={apps} toggleMenu={toggleMenu}/>
		</div>
	)
};

export default NavMenu;
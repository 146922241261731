import React, { Component } from "react";
import {connect} from "react-redux";
import {
	Tabs,
	Button,
	Select,
	Tooltip,
	Icon,
	Dialog,
	DialogContent,
	Switch,
} from "factor";

import styles from "./Mainpage.module.scss";

import Main from "components/Main/Main";
import Sidebar from "components/SideBar/Sidebar";
import Activity from "components/Activity/Activity";
import Conditional from "components/Conditional/Conditional";
import TestTable from "components/Table/TestTable";
import ModelTable from "components/ModelTable/ModelTable";

import BarChart from "components/BarChart";

import {filterColumnsArr, viewByTabs, tabs, mainpageTableSwitch} from "components/consts";

class Mainpage extends Component {
	constructor() {
		super();
		this.state = {
			campaigns: null,
			filterColumns: null,
			tab: tabs[0].value,
			viewBy: viewByTabs.map(i => i.options[0]),
			multiplier: {
				min: 1,
				max: 28,
				step: 1,
				values: [10],
				value: 1,
			},
			isExcludeDialogVisible: false,
			isMultiplierChangeDialogVisible: false,
			tableSwitch: mainpageTableSwitch[0],
			modelMode: false,
		}
	}

	componentDidUpdate() {
		!this.props.data.data.filter(i => i.selected).length && this.state.modelMode && this.setState({modelMode: false});
	}

	getRangeValue(v) {
		if(v <= 10) {
			return v / 10;
		} else {
			return (v - 10) * .5 + 1;
		}
	}

	render() {
		const {
			campaign,
			data,
		} = this.props;

		const {
			filterColumns,
			tab,
			viewBy,
			tableSwitch,
			modelMode,
		} = this.state;

		const tabIndex = tabs.findIndex(item => item.value === tab);
		const viewByOptions = viewByTabs[tabIndex].options;
		const selectedRowsLength = data.data.filter(i => i.selected).length;

		return (
			<Main>
				<Sidebar>
					<Conditional/>
					<Activity/>
				</Sidebar>
				<div className="main-content">
					<div className="main-content__tabs">
						<Tabs
							items={tabs}
							onChange={(item) => this.setState({
								tab: item.value,
							})}
							value={tab}
						/>
					</div>
					<div className="main-content__table-wrapper">
						<div className="main-content__controls">
							<div className="main-content__select">
								<Select
									options={viewByOptions}
									onChange={(value) => {
										viewBy[tabIndex] = value;
										this.setState({viewBy})
									}}
									value={viewBy[tabIndex]}
								/>
							</div>
							<Switch
								className="ml-auto"
								name="mainpage-table-switch"
								list={mainpageTableSwitch}
								value={tableSwitch === mainpageTableSwitch[1]}
								onChange={(v) => this.setState({tableSwitch: mainpageTableSwitch[v ? 1 : 0]})}
							/>
							{campaign &&
								<div className={`main-content__select _columns ml-auto`}>
									<Select
										options={filterColumnsArr}
										onChange={(value) => {
											this.setState({
												filterColumns: value
											})
										}}
										value={filterColumns}
										placeholder="Select Columns"
										isMulti
										multiPlaceholder={length => `${length} Column${length > 1 ? "s" : ""} Selected`}
										allSelectable
										dnd
									/>
								</div>
							}
						</div>
						{campaign &&
							<div className="dashboard__table-wrapper">
								<TestTable tableSwitch={tableSwitch}/>
								<Button
									className={`btn-square _filled _conflower-blue ${styles.tableBtn}`}
									disabled={!selectedRowsLength}
									onClick={() => this.setState({modelMode: !modelMode})}
								>
									{!selectedRowsLength ? "Select and add to model" : "Model Selection"}
								</Button>
								<div className={styles.tableUnderBtn}></div>
							</div>
						}
					</div>
					{!!selectedRowsLength && modelMode &&
						<ModelTable/>
					}
					<BarChart/>
				</div>
			</Main>
		)
	}
}

export default connect(
	state => ({
		campaign: state.campaign,
		data: state.data,
	})
)(Mainpage);
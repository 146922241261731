import {tableData, modelTableData} from "mocks/tableData";

let initialState = {
	// campaign: null,
	campaign: { label: "Trump 2020", value: "trump2020" },
	data: tableData,
	modelData: modelTableData,
	activeSidebar: true,
};

function reducer(state = initialState, action) {
	switch(action.type) {
		case "CAMPAIGN_CHANGE":
			return {
				...state,
				campaign: action.payload,
			};
			break;
		case "GET_DATA":
			return {
				...state,
				data: action.payload
			};
			break;
		case "GET_MODEL_DATA":
			return {
				...state,
				modelData: action.payload
			};
			break;
		case "SIDEBAR_TOGGLE":
			return {
				...state,
				activeSidebar: action.payload,
			};
		default:
			return state;
	}
}

export default reducer;
const tableData = {
	unsortedData: {
		total: true,
		selectedAll: false,
		id: "000",
		name: "",
		maxBid: "$1.03",
		spentRatio: "100%",
		frequencyCap: 7.3,
		bidPacing: "",
		totalSpent: "54",
		exclude: false,
		rowClassName: "_aggregated",
	},
	data: [
		{
			id: "101",
			name: "Bid 101",
			campaignName: "Group 1",
			maxBid: "$1.03",
			spentRatio: "100%",
			frequencyCap: 7.3,
			bidPacing: false,
			totalSpent: "54",
			exclude: true,
			selected: false,
		}
	],
};

for (let i = 2; i < 10; i++) {
	tableData.data.push({
		id: `1${i < 10 ? "0" + i : i}`,
		name: `Bid 1${i < 10 ? "0" + i : i}`,
		campaignName: `Group ${i}`,
		maxBid: "$1.03",
		spentRatio: "100%",
		frequencyCap: 7.3,
		bidPacing: !!Math.round(Math.random()),
		totalSpent: "54",
		exclude: false,
		selected: false,
	});
}

export {
  tableData,
};

const modelTableData = {
	unsortedData: {
		id: "model-table-id-00",
		total: true,
		maxBid: "$4.12",
		frequencyCap: (4 * 7.3),
		bidPacing: false,
		servingMultiplier: 2.5,
		rowClassName: "_aggregated",
	},
	data: [
		{
			creative: "Yellow Banner",
			location: "New Jersey",
			demographics: "African American",
			maxBid: "$1.03",
			frequencyCap: 7.3,
			bidPacing: false,
			servingMultiplier: 2.5,
		}
	],
};

for (let i = 2; i < 5; i++) {
	modelTableData.data.push({
		id: `model-table-id-${i < 10 ? "0" + i : i}`,
		creative: "Yellow Banner",
		location: "New Jersey",
		demographics: "African American",
		maxBid: "$1.03",
		frequencyCap: 7.3,
		bidPacing: false,
		servingMultiplier: 2.5,
	});
}

export {
	modelTableData,
};

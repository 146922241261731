import React, {Component} from "react";
import {Select, TextField} from "factor";

import styles from "./Conditional.module.scss";

import SidebarItemHeader from "components/shared/SidebarItemHeader/SidebarItemHeader";

import {ReactComponent as ConditionalIcon} from "svg/conditional.svg";

import {testOptions} from "components/consts";

const checkOptions = [{label:"Every 1 hour", value: "Every 1 hour"}, ...testOptions];
const withOptions = [{label:"Last 24hour Data", value: "Last 24hour Data"}, ...testOptions];
const ifOptions = [{label:"eCPM", value: "eCPM"}, ...testOptions];
const isOptions = [{label:"Greater than", value: "Greater than"}, ...testOptions];
const actionOptions = [{label:"Blacklist Publishers", value: "Blacklist Publishers"}, ...testOptions];
const withOptions2 = [{label:"Impressions", value: "Impressions"}, ...testOptions];
const isOptions2 = [{label:"Greater than", value: "Greater than"}, ...testOptions];

class Conditional extends Component {
	state = {
		showContent: false,
		checkSelect: null,
		withSelect: null,
		ifSelect: null,
		isSelect: null,
		greaterThan: "5",
		actionSelect: null,
		withSelect2: null,
		isSelect2: null,
		greaterThan2: "200",
	}

	render() {
		const {
			showContent,
			checkSelect,
			withSelect,
			ifSelect,
			isSelect,
			greaterThan,
			actionSelect,
			withSelect2,
			isSelect2,
			greaterThan2,
		} = this.state;

		return (
			<div className={styles.container}>
				<SidebarItemHeader
					className="_pointer"
					icon={<ConditionalIcon/>}
					title="Conditional"
					onClick={() => this.setState({showContent: true})}
					rightContent={showContent &&
						<button
							className="btn-close"
							onClick={(e) => {
								e.stopPropagation();
								this.setState({showContent: false});
							}}
						></button>
					}
				/>
				{showContent &&
					<div className={styles.form}>
						<div className={styles.item}>
							<div className={styles.label}>Check</div>
							<Select
								className={styles.select}
								value={checkSelect}
								options={checkOptions}
								onChange={v => this.setState({checkSelect: v})}
							/>
						</div>
						<div className={styles.item}>
							<div className={styles.label}>With</div>
							<Select
								className={styles.select}
								value={withSelect}
								options={withOptions}
								onChange={v => this.setState({withSelect: v})}
							/>
						</div>
						{checkSelect && withSelect &&
							<div className={styles.item}>
								<div className={styles.label}>If</div>
								<Select
									className={styles.select}
									value={ifSelect}
									options={ifOptions}
									onChange={v => this.setState({ifSelect: v})}
								/>
							</div>
						}
						{ifSelect && <React.Fragment>
							<div className={styles.item}>
								<div className={styles.label}>Is</div>
								<Select
									className={styles.select}
									value={isSelect}
									options={isOptions}
									onChange={v => this.setState({isSelect: v})}
								/>
							</div>
							{isSelect &&
								<div className={styles.formInput}>
									<TextField
										value={greaterThan}
										onChange={(v) => this.setState({greaterThan: v})}
									/>
								</div>
							}
						</React.Fragment>}
						{isSelect && greaterThan &&
							<div className={styles.item}>
								<div className={styles.label}>Action</div>
								<Select
									className={styles.select}
									value={actionSelect}
									options={actionOptions}
									onChange={v => this.setState({actionSelect: v})}
								/>
							</div>
						}
						{actionSelect &&
							<div className={styles.item}>
								<div className={styles.label}>With</div>
								<Select
									className={styles.select}
									value={withSelect2}
									options={withOptions2}
									onChange={v => this.setState({withSelect2: v})}
								/>
							</div>
						}
						{withSelect2 && <React.Fragment>
							<div className={styles.item}>
								<div className={styles.label}>Is</div>
								<Select
									className={styles.select}
									value={isSelect2}
									options={isOptions2}
									onChange={v => this.setState({isSelect2: v})}
								/>
							</div>
							{isSelect2 &&
							<div className={styles.formInput}>
								<TextField
									value={greaterThan2}
									onChange={(v) => this.setState({greaterThan2: v})}
								/>
							</div>
							}
						</React.Fragment>}
					</div>
				}
			</div>
		)
	}
}

export default Conditional;
import React, {Component} from "react";
import {TextFieldWithIcon} from "factor";

import styles from "./Activity.module.scss";

import SidebarItemHeader from "components/shared/SidebarItemHeader/SidebarItemHeader";
import EventsList from "components/shared/EventsList/EventsList";

import {ReactComponent as ActivityIcon} from "svg/activity.svg";

import {eventsList} from "components/consts";

class Activity extends Component {
	state = {
		search: "",
	}

	render() {
		const {
			search,
		} = this.state;

		return (
			<div className={styles.container}>
				<SidebarItemHeader
					icon={<ActivityIcon/>}
					title="Activity"
					rightContent={<button className={styles.headerButton}>Select</button>}
				/>
				<div className={styles.main}>
					<TextFieldWithIcon
						iconName="Search"
						value={search}
						onChange={target => this.setState({search: target.value})}
						placeholder="Search"
					/>
					<EventsList className="mt-2" list={eventsList}/>
				</div>
			</div>
		)
	}
}

export default Activity;
import React from "react";

import styles from "./SidebarItemHeader.module.scss";

const SidebarItemHeader = props => {
	const {
		className = "",
		icon,
		title,
		rightContent,
		onClick,
	} = props;

	return (
		<header className={`${styles.header} ${className}`} onClick={onClick || (() => {})}>
			<i className={styles.icon}>
				{icon}
			</i>
			<h4 className={styles.title}>
				{title}
			</h4>
			<div className={styles.right}>
				{rightContent}
			</div>
		</header>
	)
}

export default SidebarItemHeader;
import React, { Component } from 'react';
import {connect} from "react-redux";
import { Table, Checkbox, Tooltip, Icon } from "factor";

import { EditableText } from '../EditableText/EditableText';
import { CampaignChangeNameModal } from '../CampaignChangeNameModal/CampaignChangeNameModal';

import {getRowClassName} from "components/helpers";

import {getData} from "store/actions";

import styles from "./table.module.scss";

class TestTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showChangeNameDialog: false,
		}
	}

	getHeader = () => {
		return [
			{ label: '', className: '_checkbox' },
			{
				label: <React.Fragment>
					ID <Tooltip auto={false} position="right" label="ID">
					<Icon name="Info" className="_size-16"/>
				</Tooltip>
				</React.Fragment>,
				sortingKey: 'id',
				className: "_id"
			},
			{
				label: <React.Fragment>
					Max Bid <Tooltip auto={false} position="right" label="Max Bid">
					<Icon name="Info" className="_size-16"/>
				</Tooltip>
				</React.Fragment>,
				sortingKey: 'maxBid'
			},
			{
				label: <React.Fragment>
					Spent Ratio <Tooltip auto={false} position="right" label="Spent Ratio">
					<Icon name="Info" className="_size-16"/>
				</Tooltip>
				</React.Fragment>,
				sortingKey: 'spentRatio',
				className: '_spent-ratio'
			},
			{
				label: <React.Fragment>
					Frequency Cap <Tooltip auto={false} position="right" label="Frequency Cap">
					<Icon name="Info" className="_size-16"/>
				</Tooltip>
				</React.Fragment>,
				sortingKey: 'frequencyCap',
				className: '_frequency-cap'
			},
			{
				label: <React.Fragment>
					Total Spent (%) <Tooltip auto={false} position="right" label="Total Spent (%)">
					<Icon name="Info" className="_size-16"/>
				</Tooltip>
				</React.Fragment>,
				sortingKey: 'totalSpent',
				className: '_total-spent'
			},
			{
				label: <React.Fragment>
					Exclude <Tooltip auto={false} position="left" label="Exclude">
					<Icon name="Info" className="_size-16"/>
				</Tooltip>
				</React.Fragment>,
				sortingKey: 'exclude',
				className: '_exclude'
			},
		];
	}

	getBody = () => {
		const {
			data,
			getData,
		} = this.props;

		const selectedLength = data.data.filter(i => i.selected).length;

		return [
			{
				key: (d) => d.total ?
					<Checkbox
						className={(selectedLength && !d.selectedAll) ? '_dirty' : ''}
						checked={d.selectedAll}
						name={`checkbox-id-${d.id}`}
					/> :
					<Checkbox
						checked={d.selected}
						name={`checkbox-id-${d.id}`}
					/>,
				className: "_checkbox"
			},
			{
				key: (d) => {
					if (d.total) {
						return <span className="table__cell-total">
							Total
						</span>
					} else {
						return d.id;
					}
				},
				className: "_id _total"
			},
			{
				key: d => `${d.maxBid}`,
				hover: (d) => {
					return (
						<React.Fragment>
							<EditableText
								value={d.maxBid}
								onValueChanged={(newValue, cbs) => {
									this.handlePropertyChange(d, newValue, cbs, "maxBid", "Max Bid", "$");
									this.handleCancelPropertyName = this.cancelUpdatePropertyName.bind(this, cbs.cancel);
								}}
							/>
						</React.Fragment>
					);
				},
				className: "_max-bid"
			},
			{
				key: d => `${d.spentRatio}`,
				hover: (d) => {
					return (
						<React.Fragment>
							<EditableText
								value={d.spentRatio}
								onValueChanged={(newValue, cbs) => {
									this.handlePropertyChange(d, newValue, cbs, "spentRatio", "Spent Ratio", "%");
									this.handleCancelPropertyName = this.cancelUpdatePropertyName.bind(this, cbs.cancel);
								}}
							/>
						</React.Fragment>
					);
				},
				className: "_spent-ratio"
			},
			{
				key: d => d.frequencyCap,
				hover: (d) => {
					return (
						<React.Fragment>
							<EditableText
								value={d.frequencyCap}
								onValueChanged={(newValue, cbs) => {
									this.handlePropertyChange(d, newValue, cbs, "frequencyCap", "Frequency Cap");
									this.handleCancelPropertyName = this.cancelUpdatePropertyName.bind(this, cbs.cancel);
								}}
							/>
						</React.Fragment>
					);
				},
				className: "_frequency-cap"
			},
			{
				key: d => `${d.totalSpent}%`,
				className: '_number _total-spent'
			},
			{
				key: (d) =>
					<Checkbox
						checked={d.exclude}
						name={`checkbox-exclude-${d.id}`}
						onChange={(v) => this.exclude(v, d)}
					/>,
				className: '_exclude'
			},
		];
	}

	exclude = (v, d) => {
		const { data, getData } = this.props;
		const isTotalRow = d.total;

		const newData = JSON.parse(JSON.stringify(data));

		let selectedRowData = newData.data.find(i => i.id === d.id);

		if(isTotalRow) {
			selectedRowData = newData.unsortedData;
		}

		selectedRowData.exclude = v;

		getData(newData);

	}

	updateProperty = (d, value, cbs, prop) => {
		const { data, getData } = this.props;
		const isTotalRow = d.total;

		const newData = JSON.parse(JSON.stringify(data));

		let selectedRowData = newData.data.find(i => i.id === d.id);

		if(isTotalRow) {
			selectedRowData = newData.unsortedData;
		}

		selectedRowData[prop] = value;
		cbs.confirm();

		newData.data = isTotalRow ? newData.data : newData.data.map((i) => {
			return i.id === d.id ? {...i} : i;
		});
		newData.unsortedData = isTotalRow ? selectedRowData : newData.unsortedData;

		getData(newData);

		this.setState({ 
			showChangeNameDialog: false,
		});
	};
	
	cancelUpdatePropertyName = (cancel) => {
		cancel();
		this.setState({
			showChangeNameDialog: false,
		});
	};

	handlePropertyChange = (data, newValue, cbs, prop, title, units) => {
		this.handleConfirmChangeProperty = this.updateProperty.bind(this, data, newValue, cbs, prop);

		this.setState({
			showChangeNameDialog: true,
			dialogTitle: title,
			oldValue: data[prop],
			newValue: newValue,
			units: units,
		});
	};

	handleConfirmChangeProperty = () => {};
	handleCancelPropertyName = () => {};

	toggleSelect = (d) => {
		const {
			data,
			getData,
		} = this.props;

		const newData = JSON.parse(JSON.stringify(data));

		const tableData = newData.data;
		const tableFirstRow = newData.unsortedData;

		const selectedRowData = tableData.find(i => i.id === d.id);
		selectedRowData.selected = !selectedRowData.selected;
		selectedRowData.rowClassName = getRowClassName(selectedRowData.rowClassName, "_selected", selectedRowData.selected);

		newData.unsortedData = {
			...tableFirstRow,
			selectedAll: (tableData.filter(i => i.selected).length === tableData.length),
		};
		newData.data = tableData.map((i) => {
			return i.id === d.id ? {...i} : i;
		});

		getData(newData);
	}

	toggleSelectAll = (d) => {
		const {
			data,
			getData,
		} = this.props;

		const newData = JSON.parse(JSON.stringify(data));

		const tableData = newData.data;
		const tableFirstRow = newData.unsortedData;

		const selectedLength = tableData.filter(i => i.selected).length;

		if (newData.selectedAll || selectedLength > 0) {
			newData.unsortedData = {
				...tableFirstRow,
				selectedAll: false,
			};
			newData.data = tableData.map(i => {
				i.selected = false;
				i.rowClassName = getRowClassName(i.rowClassName, "_selected", false);
				return {...i};
			});
		} else {
			newData.unsortedData = {
				...tableFirstRow,
				selectedAll: true,
			};
			newData.data = tableData.map(i => {
				i.selected = true;
				i.rowClassName = getRowClassName(i.rowClassName, "_selected", true);
				return {...i};
			});
		}
		getData(newData);
	}

	rowClickHandler = (e, data, index) => {
		const { tagName } = e.target;
		const elType = e.target.getAttribute('type');
		const elName = e.target.getAttribute('name');

		if (tagName === 'INPUT' && elType === 'checkbox' && /checkbox-id/.test(elName)) {
			data.total ? this.toggleSelectAll(data) : this.toggleSelect(data);
		}
	};

	render() {
		const {
			data,
			tableSwitch,
		} = this.props;

		const {
			showChangeNameDialog,
			dialogTitle,
			oldValue,
			newValue,
			units,
		} = this.state;

		const tableData = tableSwitch === "All" ? data.data : data.data.filter(i => i.exclude);

		return (
			<React.Fragment>
				<Table
					className="table test"
					header={this.getHeader()}
					body={this.getBody()}
					unsortedData={[data.unsortedData]}
					data={tableData}
					offsetTop={70}
					rowKeyExtractor={(data) => data.id}
					rowClickHandler={this.rowClickHandler}
					windowFreeResizeEvent
				/>
				{showChangeNameDialog &&
					<CampaignChangeNameModal
					  open={showChangeNameDialog}
					  parameter={dialogTitle}
					  oldValue={oldValue}
					  newValue={newValue}
					  units={units}
					  onChange={(v) => this.setState({newValue: v})}
					  onConfirm={this.handleConfirmChangeProperty}
					  onCancel={this.handleCancelPropertyName}
					/>
				}
			</React.Fragment>
		)
	}
}

export default connect(
	state => ({
		data: state.data,
	}),
	dispatch => (getData(dispatch))
)(TestTable);
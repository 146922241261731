import React from 'react';
import {
  TextField,
  Icon,
} from 'factor';

export class EditableText extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      defaultValue: props.value || '',
      value: props.value || '',
    };
  }

  static defaultProps = {
    onValueChanged: () => {
    },
  };

  handleChange = (value) => {
    this.setState({
      value,
    });
  };

  handleBlur = () => {
    const {
      defaultValue,
      value,
    } = this.state;

    if (value && defaultValue !== value) {
      this.props.onValueChanged(value, {
        confirm: this.confirmCb,
        cancel: this.cancelCb,
      });
    }
  };

  confirmCb = () => {
    this.setState((state) => {
      return {
        defaultValue: state.value,
        value: state.value,
      };
    });
  };

  cancelCb = () => {
    this.setState((state) => {
      return {
        defaultValue: state.defaultValue,
        value: state.defaultValue,
      };
    });
  };

  render() {
    const {
      value,
    } = this.state;

    const iconEl = ((value !== this.props.value) && value.length)
      ? <Icon name="Done" />
      : null;

    return (
      <div className="editable-text">
        <TextField
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        {iconEl}
      </div>
    );
  }
}
import React, { Component } from 'react';
import {connect} from "react-redux";
import { Table, Tumbler, Button, Select } from "factor";

import { EditableText } from '../EditableText/EditableText';
import { CampaignChangeNameModal } from '../CampaignChangeNameModal/CampaignChangeNameModal';

import {getModelData} from "store/actions";

import styles from "./ModelTable.module.scss";

class ModelTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showChangeNameDialog: false,
			modelAll: false,
		}
	}

	getHeader = () => {
		return [
			{label: "Creative", sortingKey: 'creative', className: "_creative"},
			{label: "Location", sortingKey: 'location', className: "_location"},
			{label: "Demographics", sortingKey: 'demographics', className: "_demographics"},
			{label: "Max Bid", sortingKey: 'maxBid', className: `${styles.activeTh} _maxBid`},
			{label: "Frequency Cap", sortingKey: 'frequencyCap', className: `${styles.activeTh} _frequencyCap`},
			{label: "Bid Pacing", sortingKey: 'bidPacing', className: `${styles.activeTh} _bidPacing`},
			{label: "Serving Multiplier", sortingKey: 'servingMultiplier', className: `${styles.activeTh} _servingMultiplier`},
		];
	}

	getBody = () => {
		const {
			data,
			getModelData,
		} = this.props;

		const {
			modelAll,
		} = this.state;

		const selectedLength = data.data.filter(i => i.selected).length;

		return [
			{
				key: d => d.total ? "Total" : d.creative,
				className: '_creative'
			},
			{
				key: d => d.total ? "" : d.location,
				className: '_location'
			},
			{
				key: d => d.total ? "" : d.demographics,
				className: '_demographics'
			},
			{
				key: d => (!d.total && modelAll) ? "" : d.maxBid,
				hover: d => (!modelAll || d.total) &&
					<EditableText
						value={d.maxBid}
						onValueChanged={(newValue, cbs) => {
							this.handlePropertyChange(d, newValue, cbs, "maxBid", "Max Bid", "$");
							this.handleCancelPropertyName = this.cancelUpdatePropertyName.bind(this, cbs.cancel);
						}}
					/>,
				className: `${styles.activeTd} _maxBid`
			},
			{
				key: d => (!d.total && modelAll) ? "" : d.frequencyCap,
				hover: d => (!modelAll || d.total) &&
					<EditableText
						value={d.frequencyCap}
						onValueChanged={(newValue, cbs) => {
							this.handlePropertyChange(d, newValue, cbs, "frequencyCap", "Frequency Cap");
							this.handleCancelPropertyName = this.cancelUpdatePropertyName.bind(this, cbs.cancel);
						}}
					/>,
				className: `${styles.activeTd} _frequencyCap`
			},
			{
				key: d => (!d.total && modelAll) ? "" : (
					<Tumbler
						name={d.id}
						id={d.id}
						on={d.bidPacing}
						onChange={() => {
							const newData = JSON.parse(JSON.stringify(data));
							const row = d.total ? newData.unsortedData : newData.data.filter(i => i.id === d.id)[0];
							row.bidPacing = !row.bidPacing;
							getModelData(newData);
						}}
					/>
				),
				className: `${styles.activeTd} _bidPacing`
			},
			{
				key: d => (!d.total && modelAll) ? "" : (
					<Select
						className={styles.select}
						value={{label: d.servingMultiplier, value: d.servingMultiplier}}
						options={[{label: .5, value: .5}, {label: 1, value: 1}, {label: 1.5, value: 1.5}, {label: 2, value: 2}, {label: 2.5, value: 2.5}, {label: 3, value: 3}]}
						onChange={(v) => {
							const newData = JSON.parse(JSON.stringify(data));
							const row = d.total ? newData.unsortedData : newData.data.filter(i => i.id === d.id)[0];
							row.servingMultiplier = v.value;
							getModelData(newData);
						}}
					/>
				),
				className: `${styles.activeTd} _servingMultiplier`
			},
		];
	}

	updateProperty = (d, value, cbs, prop) => {
		const { data, getModelData } = this.props;
		const isTotalRow = d.total;

		const newData = JSON.parse(JSON.stringify(data));

		let selectedRowData = newData.data.find(i => i.id === d.id);

		if(isTotalRow) {
			selectedRowData = newData.unsortedData;
		}

		selectedRowData[prop] = value;
		cbs.confirm();

		newData.data = isTotalRow ? newData.data : newData.data.map((i) => {
			return i.id === d.id ? {...i} : i;
		});
		newData.unsortedData = isTotalRow ? selectedRowData : newData.unsortedData;

		getModelData(newData);

		this.setState({ 
			showChangeNameDialog: false,
		});
	};
	
	cancelUpdatePropertyName = (cancel) => {
		cancel();
		this.setState({
			showChangeNameDialog: false,
		});
	};

	handlePropertyChange = (data, newValue, cbs, prop, title, units) => {
		this.handleConfirmChangeProperty = this.updateProperty.bind(this, data, newValue, cbs, prop);

		this.setState({
			showChangeNameDialog: true,
			dialogTitle: title,
			oldValue: data[prop],
			newValue: newValue,
			units: units,
		});
	};

	handleConfirmChangeProperty = () => {};
	handleCancelPropertyName = () => {};

	render() {
		const {
			data,
		} = this.props;

		const {
			showChangeNameDialog,
			dialogTitle,
			oldValue,
			newValue,
			units,
			modelAll,
		} = this.state;

		return (
			<div className={styles.container}>
				<Button
					className={`btn-round _filled _conflower-blue ${styles.modelBtn}`}
					onClick={() => this.setState({modelAll: !modelAll})}
				>
					{modelAll ? "Model Each" : "Model All"}
				</Button>
				<Table
					className="table"
					header={this.getHeader()}
					body={this.getBody()}
					unsortedData={modelAll ? [data.unsortedData] : []}
					data={data.data}
					offsetTop={70}
					rowKeyExtractor={(data) => data.id}
					rowClickHandler={this.rowClickHandler}
					windowFreeResizeEvent
				/>
				{showChangeNameDialog &&
					<CampaignChangeNameModal
					  open={showChangeNameDialog}
					  parameter={dialogTitle}
					  oldValue={oldValue}
					  newValue={newValue}
					  units={units}
					  onChange={(v) => this.setState({newValue: v})}
					  onConfirm={this.handleConfirmChangeProperty}
					  onCancel={this.handleCancelPropertyName}
					/>
				}
			</div>
		)
	}
}

export default connect(
	state => ({
		data: state.modelData,
	}),
	dispatch => (getModelData(dispatch))
)(ModelTable);
import React, {Component} from 'react';
import {
	Dialog,
	DialogContent,
	DialogButtons,
	Checkbox,
	Button,
} from 'factor';

export class CampaignChangeNameModal extends Component {

	handleConfirm = () => {
		this.props.onConfirm();
	};

	countDecimals = (n) => {
		if (Math.floor(n.valueOf()) === n.valueOf()) return 0;
		return n.toString().split(".")[1].length || 0;
	}

	render() {
		const {
			onCancel,
			parameter,
			oldValue,
			newValue,
			onChange,
			units,
		} = this.props;

		const unitsPosition = units !== undefined ? oldValue.indexOf(units) : units;
		const oldWithoutUnits = units ? oldValue.replace(units, "") : oldValue;
		const newWithoutUnits = units ? newValue.replace(units, "") : newValue;

		let toFixedValue = Math.max(this.countDecimals(parseFloat(oldWithoutUnits, 10)), this.countDecimals(parseFloat(newWithoutUnits, 10)));

		const diffWithoutUnits = (oldWithoutUnits - newWithoutUnits).toFixed(toFixedValue);

		let diff = Math.abs(diffWithoutUnits);
		if(units) {
			diff = unitsPosition === 0 ? `${units}${diff}` : `${diff}${units}`;
		}

		return (
			<Dialog open={true} className="_changing-value" onClickOutside={onCancel}>
				<DialogContent>
					<p>Change {parameter}?</p>
					<div className="changing-value dialog__input">
						<div className="changing-value__old">
							{oldValue}
						</div>
						<div className={`changing-value__difference ${diffWithoutUnits > 0 ? "_minus" : ""}`}>
							{diffWithoutUnits > 0 ? "-" : "+"}
							{" "}
							{diff}
						</div>
						=
						<input
							type="text"
							className="changing-value__new"
							value={newValue}
							onChange={({target}) => onChange(target.value)}
						/>
					</div>
					<div className="d-flex align-items-center justify-content-center">
						<Button className="btn-square _conflower-blue mr-2" onClick={onCancel}>
							Cancel
						</Button>
						<Button className="btn-square _filled _conflower-blue" onClick={this.handleConfirm}>
							Apply
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		);
	}
}
export function getRowClassName(initialClassName, newClassName, addClass) {
	if(initialClassName === undefined) {
		if(addClass) {
			return newClassName;
		} else {
			return undefined;
		}
	} else {
		if(new RegExp(newClassName).test(initialClassName)) {
			if(addClass) {
				return initialClassName
			} else {
				return initialClassName.replace(`${newClassName}`, "").trim();
			}
		} else {
			if(addClass) {
				return `${initialClassName} ${newClassName}`;
			} else {
				return initialClassName;
			}
		}
	}
}
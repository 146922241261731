import React, {Component} from "react";
import {connect} from "react-redux";
import {Select, DatesPicker, ButtonCircle, Button} from "factor";

import NavMenu from "../NavMenu/NavMenu";

import {setCampaign} from "store/actions";

import {campaignOptions} from "components/consts";

class HeaderContent extends Component {
	constructor() {
		super();
		this.state = {
			open: false,
			dateRange: {
				start: new Date(1541192400000),
				end: new Date(1541765208969),
			},
		};
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
	}

	handleClickOutside = (e) => {
		if (this.navMenuWrapper && !this.navMenuWrapper.contains(e.target) && this.state.open) {
			this.togglePopup();
		}
	};

	togglePopup = () => {
		this.setState({
			open: !this.state.open
		});
	}

	render() {
		const {
			campaign,
			setCampaign,
		} = this.props;

		const {
			open,
			dateRange,
		} = this.state;

		return (<React.Fragment>
			<div className="nav-bar__wrapper justify-content-start">
				<div className="nav-bar__select">
					<Select
						options={campaignOptions}
						onChange={(value) => setCampaign(value)}
						value={campaign}
						id={campaign}
						name={campaign}
						placeholder="Select Campaign"
						searchable
					/>
				</div>
				<div className="nav-bar__datespicker">
					<DatesPicker
						updateDateRange={(start, end) => this.setState({
							dateRange: {
								start: new Date(start),
								end: new Date(end),
							}
						})}
						dateFormat="DD/MM/YYYY"
						dateRange={dateRange}
						datePickerProps={{
							numberOfCalendars: 2,
							maximumDate: new Date()
						}}
					/>
				</div>
			</div>
			<div className="nav-bar__menu" ref={el => this.navMenuWrapper = el}>
				<ButtonCircle
					className="_size-16"
					key="nav-menu-btn"
					iconName="Apps"
					outline={true}
					onClick={this.togglePopup}
				/>
				<NavMenu
					key="nav-menu"
					className={`${open ? "" : "_hidden"}`}
					toggleMenu={this.togglePopup}
				/>
			</div>
		</React.Fragment>);
	}
}

export default connect(
	state => ({
		campaign: state.campaign,
	}),
	dispatch => (setCampaign(dispatch))
)(HeaderContent);
import React, {Component} from "react";
import {NavigationBar} from "factor";

import HeaderContent from "./HeaderContent/HeaderContent";
import {ReactComponent as Logo} from "svg/logo.svg";

class Header extends Component {
	constructor() {
		super();
		this.navigationBar = React.createRef();
	}

	render() {
		const popupContent = "popup content";

		return (
			<NavigationBar
				ref={this.navigationBar}
				leftLogo={<div className="nav-bar__circle-icon" key="logo"><Logo/></div>}
				title="Bid Model"
				popupContent={popupContent}
				backButton={false}
			>
				<HeaderContent/>
			</NavigationBar>
		)
	}
}

export default Header;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Header from "components/Header/Header";
import Routes from "components/Routes/Routes";
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "store/index";
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
	<Provider store={store}>
		<Router>
			<React.Fragment>
				<Header/>
				<Routes/>
			</React.Fragment>
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

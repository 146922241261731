export const apps = [
	{
		image: '/img/index/app-1.svg',
		title: 'Manage Users',
		shortTitle: 'Users',
		description: 'Add members of your team and manage organizational roles',
		to: '/manage-users'
	},
	{
		image: '/img/index/app-2.svg',
		title: 'Manage Customers',
		shortTitle: 'Customers',
		description: 'Manage your customers and delegate which applications they have access to',
		to: '/customers'
	},
	{
		image: '/img/index/app-3.svg',
		title: 'Finances',
		shortTitle: 'Finances',
		description: 'Track budget and expenses',
		to: '/'
	},
	{
		image: '/img/index/app-4.svg',
		title: 'Dashboard',
		shortTitle: 'Dashboard',
		description: 'Manage Campaigns and Inventory',
		to: '/'
	},
	{
		image: '/img/index/app-5.svg',
		title: 'Campaign Builder',
		shortTitle: 'Campaigns',
		description: 'Create ad campaigns and manage goals',
		to: '/campaign-builder'
	},
	{
		image: '/img/index/app-6.svg',
		title: 'Creative Builder',
		shortTitle: 'Creatives',
		description: 'Design ads and text optimized to engage your audience',
		to: '/'
	},
	{
		image: '/img/index/app-7.svg',
		title: 'Audience Builder',
		shortTitle: 'Audiences',
		description: 'Create, track, and manage audience segments',
		to: '/'
	},
	{
		image: '/img/index/app-8.svg',
		title: 'Manage Publishers',
		shortTitle: 'Publishers',
		description: 'Manage a blacklist or whitelist of publisher with regards to ads being served',
		to: '/'
	},
	{
		image: '/img/index/app-9.svg',
		title: 'Manage Exchanges',
		shortTitle: 'Exchanges',
		description: 'Manage which exchanges to bid with and expected WinRates.',
		to: '/'
	},
];

export const campaignOptions = [
	{ label: "Trump 2020", value: "trump2020" },
	{ label: "Sanders 2020", value: "sanders2020" },
];

export const filterColumnsArr = [
	{ value: 17, label: "Campaign Name", alwaysEnabled: true },
	{ value: 1, label: "Max Bid", alwaysEnabled: true },
	{ value: 2, label: "Daily Budget", alwaysEnabled: true },
	{ value: 3, label: "Total Budget", alwaysEnabled: true },
	{ value: 15, label: "Start Date" },
	{ value: 16, label: "End Date" },
	{ value: 19, label: "Status" },
	{ value: 191, label: "Actions" },
	{ value: 4, label: "Data Cost" },
	{ value: 5, label: "Media Cost" },
	{ value: 6, label: "Creative Cost" },
	{ value: 7, label: "Treading Cost" },
	{ value: 14, label: "% Spent" },
	{ value: 22, label: "Impressions" },
	{ value: 9, label: "eCPM" },
	{ value: 23, label: "Win Rate" },
	{ value: 12, label: "Click" },
	{ value: 8, label: "eCPC" },
	{ value: 24, label: "CTR" },
	{ value: 20, label: "Conversions" },
	{ value: 10, label: "eCPI" },
	{ value: 25, label: "CVR" },
	{ value: 31, label: "Video Completing Rate" },
	{ value: 311, label: "Start" },
	{ value: 312, label: "25% Complete" },
	{ value: 313, label: "50% Complete" },
	{ value: 314, label: "75% Complete" },
	{ value: 315, label: "100% Complete" },
	{ value: 11, label: "eCPCV" },
];

export const viewByTabs = [
	{
		tab: "Creative",
		options: [
			{value: "creativeList", label: "Creative List"},
			{value: "creativeType", label: "Creative Type"},
			{value: "creativeSize", label: "Creative Size"},
			{value: "creativeGroup", label: "Creative Group"},
		]
	},
	{
		tab: "Location",
		options: [
			{ value: 22, label: "State" },
			{ value: 23, label: "City" },
			{ value: 17, label: "Zip Code" },
			{ value: 18, label: "House District" },
			{ value: 19, label: "Congress District" },
			{ value: 20, label: "Senate District" },
			{ value: 21, label: "County" },
			{ value: 24, label: "Polling Booth" },
			{ value: 241, label: "Custom Location" },
		]
	},
	{
		tab: "Demographics",
		options: [
			{ value: 2, label: "Age Group" },
			{ value: 1, label: "Gender" },
			{ value: 3, label: "Language" },
			{ value: 4, label: "Income Range" },
		]
	},
	{
		tab: "Technology",
		options: [
			{ value: 11, label: "Device type" },
			{ value: 12, label: "OS" },
			{ value: 121, label: "OS with Version" },
			{ value: 13, label: "Manufacturer" },
			{ value: 111, label: "Device" },
			{ value: 15, label: "Carrier" },
			{ value: 14, label: "Network Type" },
			{ value: 141, label: "Traffic Type" },
		]
	},
	{
		tab: "Audience",
		options: [
			{ value: "Matched Audience", label: "Matched Audience" },
			{ value: "Geo-farmed Audience", label: "Geo-farmed Audience" },
			{ value: "Purchased Audience", label: "Purchased Audience" },
			{ value: "Retargeted Audience", label: "Retargeted Audience" },
			{ value: "Custom Audience", label: "Custom Audience" },
		],
	},
	{
		tab: "Inventory",
		options: [
			{ value: 28, label: "Publishers" },
			{ value: 26, label: "Deal IDs" },
			{ value: 29, label: "Inventory Group" },
		]
	},
	{
		tab: "Time Slot",
		options: [
			{ value: "hour", label: "Hour" },
			{ value: "day", label: "Day" },
			{ value: "week", label: "Week" },
			{ value: "month", label: "Month" },
		],
	},
];

export const tabs = viewByTabs.map(item => ({
	title: item.tab,
	value: item.tab,
}));

export const mainpageTableSwitch = ["All", "Excluded"];

export const eventsList = [
	{
		weekDay: "today",
		times: [
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
			{
				time: "6:05 AM",
				text: ["created", { text: "New Campaign", url: "#"}, "(pending)"],
				app: { title: "Dashboard", url: "#" },
			},
		],
	},
	{
		weekDay: "Mon",
		times: [
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
			{
				time: "6:05 AM",
				text: ["created", { text: "New Campaign", url: "#"}, "(pending)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
			{
				time: "6:05 AM",
				text: ["created", { text: "New Campaign", url: "#"}, "(pending)"],
				app: { title: "Dashboard", url: "#" },
			},
		],
	},
	{
		weekDay: "Sun",
		times: [
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
		],
	},
	{
		weekDay: "Sat",
		times: [
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
			{
				time: "6:05 AM",
				text: ["created", { text: "New Campaign", url: "#"}, "(pending)"],
				app: { title: "Dashboard", url: "#" },
			},
		],
	},
];

export const testOptions = [
	{label: "Option 1", value: "Option 1"},
	{label: "Option 2", value: "Option 2"},
	{label: "Option 3", value: "Option 3"},
];
import React, {Component} from "react";
import {Icon} from "factor";

import styles from "./EventsList.module.scss";

class EventsList extends Component {
	state = {
		expandedDays: this.props.list.map(i => true),
	}

	render() {
		const {
			className,
			list,
		} = this.props;

		const {
			expandedDays,
		} = this.state;

		return (
			<div className={`${styles.container} ${className || ""}`}>
				{list.map((i, k) => (
					<div className={`${styles.day} ${expandedDays[k] ? "_active" : ""}`} key={i.weekDay}>
						<div
							className={styles.title}
							onClick={() => {
								expandedDays[k] = !expandedDays[k];
								this.setState({expandedDays});
							}}
						>
							{i.weekDay}
							<Icon className={styles.sort} name="Expand"/>
						</div>
						<ul className={styles.list}>
							{i.times.map((ii, kk) => (
								<li className={styles.item} key={`${i.weekDay}-${k}-${ii.time}-${kk}`}>
									<div className={styles.time}>
									<span>
										{ii.time}
									</span>
									</div>
									<p className={styles.text}>
										{ii.text.map((iii, kkk) => (
											<React.Fragment key={`${i.weekDay}-${k}-${ii.time}-${kk}-${kkk}`}>
												{(() => {
													if (typeof iii === "string") {
														return iii + " ";
													} else if (typeof iii === "object") {
														return <React.Fragment>
															<a href={iii.url}>{iii.text}</a>{" "}
														</React.Fragment>;
													} else {
														return null;
													}
												})()}
											</React.Fragment>
										))}
									</p>
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
		)
	}
}

export default EventsList;
import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";

import Main from "components/Mainpage/Mainpage";

class Routes extends Component {

	render() {
		return (
			<Switch>
				<Route path="/" component={Main} exact/>
			</Switch>
		)
	}
}

export default Routes;
import React from "react";
import {NavLink} from 'react-router-dom';

const NavMenuList = (props) => {
	const {
		list,
		toggleMenu
	} = props;

	return (
		<ul className="nav-menu__list">
			{list.map((app, i) => (
				<li className="nav-menu__item" key={i}>
					{app.externalLink ?
						<a
							href={app.to}
							target="_blank"
							className="nav-menu__link"
							onClick={toggleMenu}
						>
							<span className="nav-menu__image">
								<img src={app.image} alt=""/>
							</span>
							<span className="nav-menu__title">
								{app.shortTitle}
							</span>
						</a>
						:
						<NavLink
							to={app.to}
							className="nav-menu__link"
							onClick={toggleMenu}
						>
							<span className="nav-menu__image">
								<img src={app.image} alt=""/>
							</span>
							<span className="nav-menu__title">
								{app.shortTitle}
							</span>
						</NavLink>
					}
				</li>
			))}
		</ul>
	);
};

export default NavMenuList;
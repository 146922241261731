export const setCampaign = (dispatch) => ({
	setCampaign: (campaign) => {
		dispatch({type: "CAMPAIGN_CHANGE", payload: campaign});
	}
});

export const getData = (dispatch) => ({
	getData: (data) => {
		dispatch({type: "GET_DATA", payload: data})
	}
});

export const getModelData = (dispatch) => ({
	getModelData: (data) => {
		dispatch({type: "GET_MODEL_DATA", payload: data})
	}
});

export const toggleSidebar = (dispatch) => ({
	toggleSidebar: (show) => {
		dispatch({type: "SIDEBAR_TOGGLE", payload: show})
	}
});